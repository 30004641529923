import React from 'react';
import PropTypes from 'prop-types';

import HeaderLinkExternal from './linkExternal';

const HeaderLinkDownload = ({ className, ...rest }) => (
  <HeaderLinkExternal
    className={`${className} px-6 rounded-full text-white bg-blue-bubble duration-300 transition-transform transform-gpu md:hover:scale-105 hover:text-white`}
    {...rest}
  />
);

HeaderLinkDownload.propTypes = {
  className: PropTypes.string,
};

export default HeaderLinkDownload;
