import React from 'react';
import PropTypes from 'prop-types';

import AppStoreButton from './buttons/appStore';
import GooglePlayButton from './buttons/googlePlay';

const StoreButtons = ({
  className,
  campaign = 'default',
  googleCampaign = campaign,
  appleCampaign = campaign,
  ...rest
}) => {
  return (
    <div
      className={`flex flex-col md:block space-y-6 md:space-y-0 md:space-x-6 mx-auto ${className}`}
      {...rest}
    >
      <AppStoreButton campaign={appleCampaign} />
      <GooglePlayButton campaign={googleCampaign} />
    </div>
  );
};

StoreButtons.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.string,
  appleCampaign: PropTypes.string,
  googleCampaign: PropTypes.string,
};

export default StoreButtons;
