import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Logo = () => {
  const { siteMetaMarkdown } = useStaticQuery(graphql`
    {
      siteMetaMarkdown: markdownRemark(
        fileAbsolutePath: { regex: "/settings/site-meta.md/" }
      ) {
        frontmatter {
          title
          logo {
            childImageSharp {
              fixed(width: 160, height: 48, quality: 100) {
                src
              }
            }
          }
          logoAlt {
            childImageSharp {
              fixed(width: 160, height: 48, quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `);

  const siteMeta = siteMetaMarkdown.frontmatter;

  const siteTitle = siteMeta.title;
  const siteLogo = siteMeta.logo.childImageSharp.fixed;
  const siteLogoAlt = siteMeta.logoAlt.childImageSharp.fixed;

  return (
    <>
      {siteLogo && siteLogoAlt ? (
        <>
          <img
            src={siteLogo.src}
            className="hidden dark:block"
            alt={siteTitle}
          />
          <img src={siteLogoAlt.src} className="dark:hidden" alt={siteTitle} />
        </>
      ) : (
        siteTitle
      )}
    </>
  );
};

export default Logo;
