import React, { useRef, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import HeaderLink from './header/link';
import HeaderLinkExternal from './header/linkExternal';
import HeaderLinkDownload from './header/linkDownload';
import Logo from './header/logo';

const Header = () => {
  const { headerMarkdown } = useStaticQuery(graphql`
    {
      headerMarkdown: markdownRemark(
        fileAbsolutePath: { regex: "/settings/header.md/" }
      ) {
        frontmatter {
          menuItems {
            label
            url
            external
          }
          downloadButton {
            label
            url
          }
        }
      }
    }
  `);

  const { menuItems, downloadButton } = headerMarkdown.frontmatter;

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  const getComputedHeight = (element) => {
    if (element) {
      const height = Array.from(element.children).reduce(
        (height, child) =>
          height + parseFloat(window.getComputedStyle(child).height),
        0
      );
      return `${height}px`;
    }
    return '0px';
  };

  return (
    <>
      <header
        className="
          sticky top-0 left-0 w-full z-30
          max-h-screen overflow-y-auto overscroll-contain
          bg-section md:border-b border-accent-secondary-blue-40
        "
      >
        {/* Desktop wrapper */}
        <div
          className="
            md:container
            md:flex justify-between items-center
            md:py-4
          "
        >
          {/* Mobile top row */}
          <div
            className="
              border-b border-accent-secondary-blue-40 md:border-0
              py-4 md:py-0
            "
          >
            {/* Mobile wrapper */}
            <div
              className="
              container md:container-unset
              flex justify-between items-center"
            >
              {/* Website logo */}
              <h1 className="leading-none w-20">
                <Link to="/">
                  <Logo />
                </Link>
              </h1>

              <div className="flex items-center space-x-2 sm:space-x-4">
                <HeaderLinkDownload
                  link={downloadButton}
                  className="navbar-download md:hidden"
                />
                {/* Hamburger icon */}
                <button
                  type="button"
                  aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
                  aria-pressed={isOpen}
                  className={`
                    header__hamburger ${
                      isOpen ? 'header__hamburger--active' : ''
                    }
                    dark:bg-old-navy-blue bg-gray-middle text-white
                    md:hidden
                  `}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="first" aria-hidden="true"></div>
                  <div className="second" aria-hidden="true"></div>
                  <div className="third" aria-hidden="true"></div>
                </button>
              </div>
            </div>
          </div>

          {/* Menu */}
          <nav
            className={`
              header__menu ${
                isOpen ? 'header__menu--open' : 'header__menu--closed'
              }
              md:flex md:space-x-6 lg:space-x-10 text-center
              divide-y md:divide-y-0 divide-accent-secondary-blue-40
            `}
            ref={menuRef}
            style={{ '--height': getComputedHeight(menuRef.current) }}
          >
            {menuItems.map((item) =>
              item.external ? (
                <HeaderLinkExternal link={item} key={item.label} />
              ) : (
                <HeaderLink link={item} key={item.label} />
              )
            )}
            <HeaderLinkDownload
              link={downloadButton}
              className="navbar-download hidden md:block"
            />
          </nav>
        </div>
      </header>

      {/* Backdrop */}
      <div
        aria-hidden="true"
        className={`
          header__backdrop ${
            isOpen ? 'header__backdrop--visible' : 'header__backdrop--hidden'
          }
          fixed top-0 left-0 w-screen h-screen z-20
          md:hidden
          bg-black
        `}
        onClick={() => setIsOpen(false)}
      ></div>
    </>
  );
};

export default Header;
