import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import Header from './header';
import Footer from './footer';

const Layout = ({ children, campaign, isDark, isMinimal, ...rest }) => {
  const location = useLocation();
  const isActuallyDark =
    typeof isDark != 'undefined'
      ? isDark
      : !location.pathname.includes('/blog/');

  return (
    /* Dark mode container */
    <div className={isActuallyDark ? 'dark' : ''}>
      {/* Holy grail layout */}
      <div
        className="min-h-screen flex flex-col dark:section-dark section-light"
        {...rest}
      >
        {!isMinimal && <Header />}

        <main className="flex-grow flex flex-col">{children}</main>

        {!isMinimal && <Footer campaign={campaign} />}
      </div>
    </div>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.string,
  isDark: PropTypes.bool,
  isMinimal: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Layout;
