import React from 'react';
import PropTypes from 'prop-types';

const LiveGradient = ({ children }) => (
  <div className="section-colored relative">
    <div className="absolute -top-12 h-full w-full left-0 opacity-50" />
    <div className="absolute -bottom-12 h-full w-full left-0 opacity-50" />
    <div className="relative bg-splice-gradient-horizontal">{children}</div>
  </div>
);

LiveGradient.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LiveGradient;
