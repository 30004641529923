import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

const HeaderLink = ({ link, className = '', ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname.includes(link.url);

  return (
    <Link
      to={link.url}
      className={`
        header__menu__link
        ${isActive ? 'contrast-high' : 'contrast-base'}
        ${className}
      `}
      {...rest}
    >
      {link.label}
    </Link>
  );
};

HeaderLink.propTypes = {
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

export default HeaderLink;
