import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useAdjustLink from '../../../hooks/use-adjust-link';

import AppStoreIcon from '../../../assets/app-store.svg';

const AppStoreButton = ({
  size,
  className = '',
  campaign = 'default',
  url = null,
  ...rest
}) => {
  const ctaRef = useRef();
  if (url === null) {
    url = useAdjustLink({ campaign, store: 'apple' }, ctaRef);
  }
  return (
    <a
      href={url}
      ref={ctaRef}
      className={`btn ${
        size == 'small' ? 'btn--store-small' : 'btn--store'
      } ${className}`}
      {...rest}
    >
      <AppStoreIcon />
      <div className="leading-self">
        <small className="block mb-1 R12 text-gray-middle">Get on the</small>
        App Store
      </div>
    </a>
  );
};

AppStoreButton.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  campaign: PropTypes.string,
  url: PropTypes.string,
  appleCampaign: PropTypes.string,
  googleCampaign: PropTypes.string,
};

export default AppStoreButton;
