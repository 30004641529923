import React from 'react';
import PropTypes from 'prop-types';

const HeaderLinkExternal = ({ link, className = '', ...rest }) => (
  <a
    href={link.url}
    target="_blank"
    rel="noreferrer"
    className={`header__menu__link ${className}`}
    {...rest}
  >
    {link.label}
  </a>
);

HeaderLinkExternal.propTypes = {
  link: PropTypes.object,
  className: PropTypes.string,
};

export default HeaderLinkExternal;
